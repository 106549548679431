import React from "react";
import { Link, withRouter } from "react-router-dom";
import Countdown from './Countdown';

function Navigation(props) {
  function mobileMenu(e) {
    e.preventDefault()
    let nav = document.getElementById('navMenu');
    if (nav.classList.contains('open')) {
      nav.classList.remove('open');
    } 
    else {
      nav.classList.add('open');
    }
  }
  function contextBasedHeader() {
    switch(props.location.pathname) {
        /*case "/info":
          return (
            <div className="fullHeader">
              <img src="ombrollopet.jpg" alt="header"/>
            </div>
          );
        case "/boendetips":
          return (
            <div className="fullHeader">
              <img src="tips1.jpg" alt="header"/>
            </div>
          );
        case "/toastmasters":
            return (
              <div className="fullHeader">
                <img src="toast2.jpg" alt="header"/>
              </div>
            );
        case "/guide":
          return (
            <div className="fullHeader">
              <img src="guide1.jpg" alt="header"/>
            </div>
          );
        case "/anmalan":
            return (
              <div className="fullHeader">
                <img src="register4.jpg" alt="header"/>
              </div>
            );*/
      default:
        return (
          <div>
            <Countdown />
            {/* <img src="welcome.jpg" alt="header"/> */}
              <h1>
                <Link to="/">Simon och Jennie</Link>
              </h1>
          </div>
        );
    }
  }
  return (
    <div className="navigation">
      <div className="overtheflow">
        {contextBasedHeader()}
      <div className="otf-navigation" id="navMenu" onClick={mobileMenu} >
        <ul>
          <li
            className={`nav-item  ${
              props.location.pathname === "/" ? "active" : ""
            }`}
          >
            <Link className="nav-link" to="/">
              Välkommen
              <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li
            className={`nav-item  ${
              props.location.pathname === "/info" ? "active" : ""
            }`}
          >
            <Link className="nav-link" to="/info">
              Om Bröllopet
            </Link>
          </li>
          <li
            className={`nav-item  ${
              props.location.pathname === "/anmalan" ? "active" : ""
            }`}
          >
            <Link className="nav-link" to="/anmalan">
              Anmälan
            </Link>
          </li>
        </ul>
      </div>
      </div>
    </div>
  );
}

export default withRouter(Navigation);