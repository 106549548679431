import React from "react";

function Anmalan() {
  return (
    <div className="content anmalan">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-12">
            <h1 className="font-weight-light">Anmälan</h1>
            <p>
              Här kan du anmäla dig och eventuellt din respektive till bröllopet.
            </p>
            <p>
               Om du mot förmodan vet redan nu att du inte kan komma ber vi dig att ändå anmäla detta. Välj bara alternativet "Jag kan tyvärr inte" i formuläret nedan.
            </p>
          </div>
        </div>
        <iframe title="anmalan" src="https://docs.google.com/forms/d/e/1FAIpQLScghWOaRZ-54YcyyOCnacyoxCuDR0yEjEdAor9dH-sYBvrFpw/viewform?embedded=true" width="100%" height="1800" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
      </div>
    </div>
  );
}

// <iframe title="anmalan" src="https://docs.google.com/forms/d/e/1FAIpQLScghWOaRZ-54YcyyOCnacyoxCuDR0yEjEdAor9dH-sYBvrFpw/viewform?embedded=true" width="100%" height="1800" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
// Simon Jonsson: https://docs.google.com/forms/d/e/1FAIpQLScghWOaRZ-54YcyyOCnacyoxCuDR0yEjEdAor9dH-sYBvrFpw/viewform?usp=sf_link

export default Anmalan;