import React from "react";
import { Link } from "react-router-dom";

function OmBrollopet() {
  return (
    <div className="content ombrollopet">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-12">
            <h1 className="font-weight-light">Om bröllopet</h1>
            <h2 className="font-weight-bold">O.S.A senast den 1/6 <Link to="/anmalan">här</Link></h2>
            <p>
              Kl <b>14.30</b> börjar våran vigsel i <b>Snöstorps kyrka</b><br />
              Kyrkan ligger på <a href="https://maps.app.goo.gl/6zgTxuQpXuE19Npy8">Ljungbyvägen 72, 302 56 Halmstad</a>.
            </p>
            <p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2390.645569452711!2d12.913128904497993!3d56.67026545152866!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4651a3a5a923df07%3A0x14f64001d484acbc!2sSn%C3%B6storps%20kyrka!5e0!3m2!1sen!2sse!4v1714822608253!5m2!1sen!2sse" width="400" height="300" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </p>
            <p>
              Efter vigseln beger vi oss till Söstorps Allmogegård för mat och fest.<br />
              På middagen så är det 18 år+ som gäller.<br />
              Snöstorps Allmogegård ligger på <a href="https://maps.app.goo.gl/UGfs6e9Fd7ZmLUbR9">Kappgatan 42, 302 59 Halmstad</a>.
            </p>
            <p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2191.462484324007!2d12.936275377740628!3d56.68348371992503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4651a39371846777%3A0xeb2c4c29ad588286!2sSn%C3%B6storps%20Allmogeg%C3%A5rd!5e0!3m2!1sen!2sse!4v1714823283353!5m2!1sen!2sse" width="400" height="300" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </p>
            <p>
              Vill man hålla något tal eller liknande så kan man höra av sig till våran fantastiska toastmaster Kajsa på <span class="obf tel">42</span>.
            </p>
            <p>
              Klädsel är valfri.
            </p>
            <p>
              Om ni funderar på bröllopspresenter (som absolut inte är ett måste) så hade vi uppskattat ett bidrag till vårat framtida hus.
            </p>
            <p>
              <b>Kontonummer</b><br />
              <span class="obf acc">8 32</span><br />
              Swedbank
            </p>
            <p>
              <b>Swish</b><br />
              Simon: <span class="obf simtel">40</span><br />
              Jennie: <span class="obf jentel">35</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OmBrollopet;