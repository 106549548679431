import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Start, OmBrollopet, Anmalan } from "./components";
import './App.css'
function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Start />} />
          <Route path="/info" exact component={() => <OmBrollopet />} />
          <Route path="/anmalan" exact component={() => <Anmalan />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}
/*start, info, program, hitta hit, boende och anmälan */
export default App;