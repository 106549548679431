import React from "react";

class Countdown  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: null,
			targetDate: new Date(2024, 6, 13, 14, 30),
            days: '--',
            hours: '--',
            minutes: '--',
            seconds: '--'
		}
        this.setState({
            timer: setInterval(this.tick.bind(this), 1000),
        });
        this.tick();
    }
    render() {
        return (
            <div className='countdown'>
                <div className='table-row'>
                    <div className="table-cell-large">
                        {this.state.days}
                    </div>
                    <div className='table-cell-large'>
                        {this.state.hours}
                    </div>
                    <div className='table-cell-large'>
                        {this.state.minutes}
                    </div>
                    <div className='table-cell-large'>
                        {this.state.seconds}
                    </div>
                </div>
                <div className='table-row'>
                    <div className="table-cell-small">
                        Dagar
                    </div>
                    <div className='table-cell-small'>
                        Timmar
                    </div>
                    <div className='table-cell-small'>
                        Minuter
                    </div>
                    <div className='table-cell-small'>
                        Sekunder
                    </div>
                </div>
            </div>
        );
    }
    tick() {
        const _S_PER_MIN = 60;
        const _S_PER_HOUR = _S_PER_MIN * 60;
        const _S_PER_DAY = _S_PER_HOUR * 24;

        let totalSeconds = Math.floor((this.state.targetDate - new Date()) / 1000);
        totalSeconds = Math.max(0, totalSeconds);

        let days = Math.floor(totalSeconds / _S_PER_DAY);
        totalSeconds -= days * _S_PER_DAY;

        let hours = Math.floor(totalSeconds / _S_PER_HOUR);
        totalSeconds -= hours * _S_PER_HOUR;

        let minutes = Math.floor(totalSeconds / _S_PER_MIN);
        totalSeconds -= minutes * _S_PER_MIN;

        this.setState({
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: totalSeconds
        });
    }
}

export default Countdown; 