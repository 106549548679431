import React from "react";
import { Link } from "react-router-dom";

function Start() {
  return (
    <div className="content start">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-12">
            <p className="intro">
              Den 13e Juli <b>klockan 14:30</b> gifter vi oss i Snöstorps kyrka,<br/>
              och vi hoppas att ni vill vara med och dela dagen med oss!
            </p>
            <Link to="/info">
              <img src="simonochjennie_welcome.jpeg" alt="" width="50%"/>
            </Link>
            <p className="intro">
              <b>O.S.A senast den 1/6 <Link to="/anmalan">här!</Link></b>
            </p>
            <p className="intro">
              Varmt välkomna!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Start;