import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer className="py-3">
        <div className="container">
          <p className="m-0 text-center">
            Copyright &copy; Simon &amp; Jennie 2024
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;